/* Home page background image and contents*/

@font-face {
  font-family: "Suisse Works Bold";
  src: url("https://db.onlinewebfonts.com/t/27cb2381f8e348c531e19a16b9921082.eot"); /* IE9*/
  src: url("https://db.onlinewebfonts.com/t/27cb2381f8e348c531e19a16b9921082.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("https://db.onlinewebfonts.com/t/27cb2381f8e348c531e19a16b9921082.woff2") format("woff2"),
    /* chrome firefox */ url("https://db.onlinewebfonts.com/t/27cb2381f8e348c531e19a16b9921082.woff") format("woff"),
    /* chrome firefox */ url("https://db.onlinewebfonts.com/t/27cb2381f8e348c531e19a16b9921082.ttf") format("truetype"),
    /* chrome firefox opera Safari, Android, iOS 4.2+*/
      url("https://db.onlinewebfonts.com/t/27cb2381f8e348c531e19a16b9921082.svg#Suisse Works Bold") format("svg"); /* iOS 4.1- */
}

.block-cover {
  background-image: url("images/bg.webp");

  height: 100%;
  position: relative;
  background-size: cover;
  background-position: 50%;

  width: 100%;
  height: 110vh;

  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}
.main-home-content-margin {
  margin-left: 10%;
  margin-right: 10%;
}

.main-container {
  overflow-x: hidden;
}

.floating-div-container {
  position: fixed;
  right: 20px;
  cursor: pointer;
  bottom: 20px;
}

.floating-div-child {
  display: flex;
  width: 110px;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  background-color: #fff;
  background-clip: border-box;
  justify-content: center;
  align-items: center;
  color: #03878f;
}
.whatsapp-container {
  background: #0fdd5b;
  padding-left: 9px;
  padding-top: 9px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: auto;
}

.phone-container {
  background: #0fdd5b;
  padding-left: 9px;
  padding-top: 9px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: auto;
}

.email-containe {
  background: #0b757d;
  padding-left: 9px;
  padding-top: 9px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin: auto;
}

.icon-styles-main {
  margin-left: 3px;
  margin-top: 2px;
  font-size: 19px;
}

.inner-icon-style {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.about-popup {
  /* display: none; */
  width: 912px;
  height: 580px;
  color: #ffffff;
  background: #f77a0e;
  border-radius: 5px;
  padding: 60px;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  text-align: justify;
  z-index: 3000;
  overflow-y: auto;
}

.social-share,
.close-sec {
  display: flex;
  justify-content: flex-end;
}

.close-window {
  color: #f77a0e;
  background: #ffffff;
  border-radius: 5px;
  padding: 10px 25px;
  margin-top: 1px;
  cursor: pointer;
  position: fixed;
  top: 10%;

}

@media only screen and (max-width: 600px) {
  .floating-div-container {
    display: none;
  }
}
